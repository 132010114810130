import * as React from 'react'
import { PagedTableControls } from '@tomra/react-table'
import { InstallationStatusData, KeyInstallationStatus, KeyMetadata } from '../domain/DTO'
import colors from '../styles/colors'
import { KeyInstallationStatusEnum } from '../domain/Enums'
import { ReactNode, useState } from 'react'
import { SortableHeader, useTableSorting } from '../components/TableColumnSort'
import { Link } from 'react-router-dom'
import { fetchKeyMetadataForActiveInstallation } from '../lib/service'
import { logError } from '../lib/logError'

export const InstallationsStatusOverview = (props: InstallationStatusData) => {
  const keyInstallationsStatusById = props.installationsStatusByInstallationId
  const keyInstallationsStatuses = props.keyInstallationsStatuses
  const [currentPage, setCurrentPage] = useState(1)
  const [filter, setFilter] = useState('')
  const [recordsPerPage, setRecordsPerPage] = useState(10)
  const [keyMetadata, setKeyMetadata] = useState<KeyMetadata>({} as KeyMetadata)
  const paginationOptions = [10, 50, 200, 1000]

  const sorting = useTableSorting<KeyInstallationStatus>(
    {
      Serial: (k) => k.serialNumber,
      'Key Status': (k) => k.installationStatus,
      'Last Updated': (k) => k.savedTimestamp,
      Details: (k) => (k.statusDetails ? k.statusDetails : ''),
    },
    'Last Updated'
  )

  const sortedAndFilteredInstallationsMap = new Map(
    Array.from(sorting.sort(keyInstallationsStatusById)).filter((keyStatus) => {
      const searchText = keyStatus[1][0].serialNumber
      return searchText.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
    })
  )

  function decideRowColor(keyInstallationStatus: KeyInstallationStatus) {
    let rowColor = ''
    let keyInstallationStatusMatch
    if (keyInstallationStatus.installationStatus === KeyInstallationStatusEnum.NOT_OK) {
      // find installation status for different keyType but same serial number that at least one has status NOT_OK
      keyInstallationStatusMatch = keyInstallationsStatuses.find(
        (obj) =>
          obj.serialNumber === keyInstallationStatus.serialNumber && obj.keyType !== keyInstallationStatus.keyType
      )
      if (
        keyInstallationStatusMatch &&
        keyInstallationStatusMatch.installationStatus === KeyInstallationStatusEnum.OK
      ) {
        rowColor = colors.LIGHT_YELLOW
      } else if (
        (keyInstallationStatusMatch &&
          keyInstallationStatusMatch.installationStatus === KeyInstallationStatusEnum.NOT_OK) ||
        keyInstallationStatusMatch === undefined
      ) {
        rowColor = colors.LIGHT_RED
      }
    } else if (keyInstallationStatus.installationStatus === KeyInstallationStatusEnum.OK) {
      // find installation status for different keyType but same serial number that have both status OK
      let keyInstallationStatusMatch = keyInstallationsStatuses.find(
        (obj: any) =>
          obj.serialNumber === keyInstallationStatus.serialNumber &&
          obj.keyType !== keyInstallationStatus.keyType &&
          obj.installationStatus === KeyInstallationStatusEnum.OK
      )
      if (keyInstallationStatusMatch) {
        rowColor = colors.LIGHT_GREEN
      } else {
        rowColor = colors.LIGHT_YELLOW
      }
    }
    return rowColor
  }

  function getInstallationsComponents(installations: Map<string, KeyInstallationStatus[]>) {
    const installationsComponents: Array<ReactNode> = []
    new Map(Array.from(installations).slice(recordsPerPage * (currentPage - 1), recordsPerPage * currentPage)).forEach(
      (installation) => {
        installationsComponents.push(
          <tr style={{ backgroundColor: decideRowColor(installation[0]) }}>
            <td>
              <Link to={`/installations/${installation[0].serialNumber}`} className="link ml-sm>">
                {installation[0].serialNumber}
              </Link>
            </td>
            <td>
              {installation[0].keyType + ` -> ` + installation[0].installationStatus}
              <br />
              {installation.length === 2 ? installation[1].keyType + `-> ` + installation[1].installationStatus : ''}
            </td>
            <td>
              {new Date(installation[0].savedTimestamp).toLocaleString()}
              <br />
              {installation.length === 2 ? new Date(installation[1].savedTimestamp).toLocaleString() : ''}
            </td>
            <td>
              {installation[0].statusDetails}
              <br />
              {installation.length === 2 ? installation[1].statusDetails : ''}
            </td>
          </tr>
        )
      }
    )
    return installationsComponents
  }

  return (
    <div className="card">
      {keyMetadata.installationIdFromMasterdata !== undefined ||
      keyMetadata.oneringIdFromConfigurationManager !== undefined ? (
        <div className="alert alert-warning">
          InstallationId: {keyMetadata.installationIdFromMasterdata}
          <br />
          OneringId: {keyMetadata.oneringIdFromConfigurationManager}
          <br />
        </div>
      ) : (
        ''
      )}
      <div className="my-sm pl-lg">
        <label htmlFor="search">Search:</label>
        <input
          type="text"
          className="textfield w-auto mx-md"
          data-testid="search"
          onChange={(e) => {
            const value = e.target.value
            setFilter(value.toLocaleLowerCase())
            setCurrentPage(1)
            if (value.length === 9) {
              if (keyInstallationsStatusById.get(value)) {
                fetchKeyMetadataForActiveInstallation(value)
                  .then((remoteMetadata) => {
                    console.log('KeyMetadata ', remoteMetadata)
                    setKeyMetadata(remoteMetadata)
                  })
                  .catch((error) => {
                    logError(new Error('Unable to fetch key-metadata for installation'), error)
                  })
              }
            } else {
              setKeyMetadata({} as KeyMetadata)
            }
          }}
        />
        <abbr className="no-underline" title={'You can search for serial number'}>
          ❔
        </abbr>
      </div>
      <table className="table">
        <thead>
          <tr>
            <SortableHeader name="Serial" currentSort={sorting.currentSort} className={'w-3 max-w-5'} />
            <SortableHeader name="Key Status" currentSort={sorting.currentSort} className={'w-3 max-w-5'} />
            <SortableHeader name="Last Updated" currentSort={sorting.currentSort} className={'w-3 max-w-5'} />
            <SortableHeader name="Details" currentSort={sorting.currentSort} className={'w-10 max-w-15'} />
          </tr>
        </thead>
        <tbody>{getInstallationsComponents(sortedAndFilteredInstallationsMap)}</tbody>
      </table>
      <PagedTableControls
        currentPage={currentPage}
        numOfItems={keyInstallationsStatuses.length}
        recordsPerPage={recordsPerPage}
        onPageUpdate={setCurrentPage}
        recordsPerPageOptions={paginationOptions}
        onRecordsPerPageChange={(recordsPerPage: number) => {
          setRecordsPerPage(recordsPerPage)
          setCurrentPage(1)
        }}
      />
    </div>
  )
}
