import { useState, useEffect } from 'react'
import { authenticatedGet } from './authenticatedGet'

// useCallback() is required to do on the incoming transform function (if it is defined as a lambda in the render function)
// before passing into useRemoteData. This is to avoid retriggering the effect on every render
// (Which will happen if the function is defined in the render)
export function useRemoteData(resourceUrl: string) {
  const [requestStatus, setRequestStatus] = useState('loading')
  const [response, setResponse] = useState<any>()

  useEffect(() => {
    let mounted = true

    if (!response) {
      authenticatedGet(resourceUrl)
        .then((value) => {
          if (mounted) {
            setResponse(value)
            setRequestStatus('success')
          }
        })
        .catch(() => {
          if (mounted) {
            setRequestStatus('failed')
          }
        })

      return () => {
        mounted = false
      }
    }
  }, [resourceUrl, response])

  return {
    requestStatus,
    response,
  }
}
