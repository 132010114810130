const spacing = {
  xxs: '1px',
  xs: '4px',
  sm: '8px',
  md: '16px',
  lg: '24px',
  xl: '36px',
  'spacing-20': '20%',
}

export default spacing
