import { fetch } from '@tomra/client-side-http-client'
import { authentication } from './authentication'

const config = {
  fetchNewToken: authentication.fetchNewToken,
  getToken: authentication.getToken,
}

export function authenticatedFetch(url: string, options: RequestInit, retryCount?: number): Promise<any> {
  return fetch(url, options, { ...config, retryCount }).run()
}

export function authenticatedGet(url: string, returnRawResponse: boolean = false) {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    returnRawResponse,
  }

  return authenticatedFetch(`${url}`, options)
}
