import { Link } from 'react-router-dom'

export const NotFound = () => {
  return (
    <div className="alert alert-danger">
      <h1 className="text-lg">Not found</h1>
      Maybe you've misspelled the url?
      <div>
        <Link to="/" className="link">
          Return to home
        </Link>
      </div>
    </div>
  )
}
