import styled from 'styled-components'
import spacing from './spacing'
import colors from './colors'

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${spacing.md};
  h2 {
    margin: 0;
  }
  a {
    color: ${colors.PRIMARY};
  }
`
