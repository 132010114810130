import { authenticatedGet } from './httpClient'

import { API_HOST } from './environment'
import { KeyInstallationStatus, KeyMetadata } from '../domain/DTO'

export function fetchStatusForAllKeyInstallationAccociations(): Promise<KeyInstallationStatus[]> {
  return authenticatedGet(`${API_HOST}/v1/key-status/installations`)
    .run()
    .then((resp: { data: KeyInstallationStatus[] }) => resp.data)
}

export function fetchKeyMetadataForActiveInstallation(serialNumber: string): Promise<KeyMetadata> {
  return authenticatedGet(`${API_HOST}/v1/key-metadata/${serialNumber}`)
    .run()
    .then((resp: { data: KeyMetadata }) => resp.data)
}
