import { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import { LogoutIcon } from '../icons'
import { AppMenuMountDiv } from './AppMountMenuDiv'
import Colors from '../styles/colors'

type HeaderProps = {
  doLogout: () => void
}

export const TopHeader: FunctionComponent<HeaderProps> = ({ doLogout }) => {
  return (
    <div className="navbar">
      <Link to="/">DSMD Manager</Link>
      <Link className="navtab" to="/" color={Colors.WHITE}>
        Installations
      </Link>
      <div className="flex gap-md">
        <AppMenuMountDiv />
        <button onClick={doLogout}>
          <LogoutIcon color="white" />
        </button>
      </div>
    </div>
  )
}
