import jwtDecode from 'jwt-decode'
import { authentication } from './authentication'

export function logError(error: Error, cause?: Error, context?: any) {
  if (cause instanceof Error) {
    error.message += `, Caused by: ${cause.toString()}`
    error.stack += '\nCaused by: ' + cause.stack
  }

  if (window.DD_RUM) {
    const ctx = { ...context }
    try {
      ctx.user = { id: (jwtDecode(authentication.getToken()) as any).sub }
    } catch (e) {}
    window.DD_RUM.addError(error, ctx)
  } else {
    // eslint-disable-next-line no-console
    console.error(error)
  }
}
