import { useRemoteData } from './lib/RemoteData'
import { KeyInstallationStatus } from './domain/DTO'
import { API_HOST } from './lib'
import { TopHeader } from './components/TopHeader'
import { InstallationsStatusOverview } from './Pages/InstallationsStatusOverview'
import { InstallationStatusDetails } from './Pages/InstallationStatusDetails'
import { Navigate, Route, Routes } from 'react-router-dom'
import { NotFound } from './NotFound'

type Props = {
  doLogout: () => void
}

export const App = ({ doLogout }: Props) => {
  const installationsUrl = `${API_HOST}/v1/key-status/installations`
  const { requestStatus, response } = useRemoteData(installationsUrl)

  let keyInstallationsStatusById: Map<string, KeyInstallationStatus[]> = new Map()
  let keyInstallationsStatuses: KeyInstallationStatus[] = []

  if (requestStatus === 'success') {
    keyInstallationsStatuses = response.data.keyInstallationsStatuses
    keyInstallationsStatuses.forEach(function (value) {
      if (keyInstallationsStatusById.has(value.serialNumber)) {
        keyInstallationsStatusById.get(value.serialNumber)?.push(value)
      } else {
        keyInstallationsStatusById.set(value.serialNumber, [value])
      }
    })
  }
  return (
    <>
      <TopHeader doLogout={doLogout} />
      <div className="px-lg pt-md">
        <Routes>
          <Route
            path="/installations"
            element={
              <InstallationsStatusOverview
                installationsStatusByInstallationId={keyInstallationsStatusById}
                keyInstallationsStatuses={keyInstallationsStatuses}
              />
            }
          />
          <Route
            path="/installations/:id"
            element={<InstallationStatusDetails installationsStatusByInstallationId={keyInstallationsStatusById} />}
          />
          <Route path="/" element={<Navigate to="/installations" />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </>
  )
}

export default App
