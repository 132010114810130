import { useParams } from 'react-router'
import { TitleContainer } from '../styles/PageLayout'
import colors from '../styles/colors'
import { Link } from 'react-router-dom'
import { KeyInstallationStatusEnum } from '../domain/Enums'
import { KeyInstallationStatus } from '../domain/DTO'
import * as React from 'react'
import { ArrowRightIcon } from '../icons'
import styled from 'styled-components'

type InstallationsStatusOverviewProps = {
  installationsStatusByInstallationId: Map<string, KeyInstallationStatus[]>
}

export const InstallationStatusDetails = (props: InstallationsStatusOverviewProps) => {
  const StyledTd = styled.td`
    width: 20px;
    max-width: 160px !important;
  `
  const { id } = useParams<{ id: string }>()
  if (!id) {
    throw new Error('Missing id parameter')
  }
  const keyInstallationsStatusById = props.installationsStatusByInstallationId
  const installationDetails = keyInstallationsStatusById.get(id)

  if (installationDetails !== undefined) {
    let numberOfKeysWithStatus = installationDetails.length

    let keyOneCardColor =
      installationDetails.length >= 1 && installationDetails[0].installationStatus === KeyInstallationStatusEnum.OK
        ? colors.LIGHT_GREEN
        : colors.LIGHT_RED
    let keyTwoCardColor =
      installationDetails.length === 2 && installationDetails[1].installationStatus === KeyInstallationStatusEnum.OK
        ? colors.LIGHT_GREEN
        : colors.LIGHT_RED

    return (
      <div>
        <TitleContainer>
          <Link to="/installations">
            <h2>Installations</h2>
          </Link>
          {<ArrowRightIcon color={colors.PRIMARY} />}
          <h2>{numberOfKeysWithStatus >= 1 ? installationDetails[0].serialNumber : `no keys found`}</h2>
        </TitleContainer>
        <div className="card" style={{ backgroundColor: keyOneCardColor }}>
          <table className="table">
            <tbody>
              <tr>
                <StyledTd className={'p-md'}>
                  <b>Key type:</b>
                </StyledTd>
                <StyledTd className={'w-10'}> {installationDetails[0].keyType ?? ``}</StyledTd>
              </tr>
              <tr>
                <StyledTd className={'p-md'}>
                  <b>Key id(oneringId):</b>
                </StyledTd>
                <StyledTd> {installationDetails[0].keyId ?? ``}</StyledTd>
              </tr>
              <tr>
                <StyledTd className={'p-md'}>
                  <b>Hash:</b>
                </StyledTd>
                <StyledTd> {installationDetails[0].keyHash ?? ``}</StyledTd>
              </tr>
              <tr>
                <StyledTd className={'p-md'}>
                  <b>Status:</b>
                </StyledTd>
                <StyledTd> {installationDetails[0].installationStatus ?? ``}</StyledTd>
              </tr>
              <tr>
                <StyledTd className={'p-md'}>
                  <b>Last updated:</b>
                </StyledTd>
                <StyledTd> {new Date(installationDetails[0].savedTimestamp).toLocaleString() ?? ``}</StyledTd>
              </tr>
              <tr>
                <StyledTd className={'p-md'}>
                  <b>Details:</b>
                </StyledTd>
                <StyledTd> {installationDetails[0].statusDetails ?? installationDetails[0].statusDetails}</StyledTd>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        {installationDetails.length === 2 ? (
          <div className="card" style={{ backgroundColor: keyTwoCardColor }}>
            <table className="table">
              <tbody>
                <tr>
                  <StyledTd className={'p-md'}>
                    <b>Key type:</b>
                  </StyledTd>
                  <StyledTd className={'w-10'}> {installationDetails[1].keyType ?? ``}</StyledTd>
                </tr>
                <tr>
                  <StyledTd className={'p-md'}>
                    <b>Key id(oneringId):</b>
                  </StyledTd>
                  <StyledTd> {installationDetails[1].keyId ?? ``}</StyledTd>
                </tr>
                <tr>
                  <StyledTd className={'p-md'}>
                    <b>Hash:</b>
                  </StyledTd>
                  <StyledTd> {installationDetails[1].keyHash ?? ``}</StyledTd>
                </tr>
                <tr>
                  <StyledTd className={'p-md'}>
                    <b>Status:</b>
                  </StyledTd>
                  <StyledTd> {installationDetails[1].installationStatus ?? ``}</StyledTd>
                </tr>
                <tr>
                  <StyledTd className={'p-md'}>
                    <b>Last updated:</b>
                  </StyledTd>
                  <StyledTd> {new Date(installationDetails[1].savedTimestamp).toLocaleString() ?? ``}</StyledTd>
                </tr>
                <tr>
                  <StyledTd className={'p-md'}>
                    <b>Details:</b>
                  </StyledTd>
                  <StyledTd> {installationDetails[1].statusDetails ?? ``}</StyledTd>
                </tr>
              </tbody>
            </table>
          </div>
        ) : (
          ``
        )}
      </div>
    )
  } else {
    return (
      <div>
        <TitleContainer>
          <Link to="/installations">
            <h2>Installations</h2>
          </Link>
          <ArrowRightIcon color={colors.PRIMARY} />
          <h2>{`could not find installation`}</h2>
        </TitleContainer>
      </div>
    )
  }
}
